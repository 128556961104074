import { Link, NavLink } from 'react-router-dom';
import { TableRow, TableCell, TableBody, Stack } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableDropDown } from './TableDropDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import React, { useEffect } from 'react'
import { uniqueId } from 'lib';
import ExpendableRow from 'components/ExpendableRow';

interface TableContentProps {
  rows: any[];
  handleArchive?: (id: string) => void;
  handleEdit?: (id: string) => void;
  handleRemove?: (id: string) => void;
  type?: string;
  rowsPerPage: number;
  page: number;
  hideArchive?: boolean;
  tableName?: string;
  columns: any;
  handleEditDropDown?: (templateObj: any) => void;
  handleRemoveDropDown?: (id: any) => void;
  fetchData?: () => void;
  updateTable?: () => void;
  tableDropDownSort: any;
  pageChangeHandler?: any;
  currentRow: any,
  setCurrentRow: any,
  expendable?: boolean
}

function TableContentDetails({
  type,
  rowsPerPage,
  rows,
  handleEdit = () => { },
  page,
  handleArchive = () => { },
  handleRemove = (id: any) => { },
  hideArchive,
  tableName,
  handleEditDropDown = () => { },
  handleRemoveDropDown = () => { },
  fetchData = () => { },
  updateTable = () => { },
  columns,
  tableDropDownSort,
  pageChangeHandler,
  currentRow,
  setCurrentRow,
  expendable = false,
}: TableContentProps): JSX.Element {
  const displayRows = rowsPerPage > 0 && type !== 'noScheduleReport' ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows;

const rows1=[
  {
      "label": "123 Home Care",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Stephanie Pierini",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "A1 Healthcare Home Health and Hospice",
      "values": [
          1,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Oksana Chigirina",
              "values": [
                  1,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "ACC - Care Center",
      "values": [
          1,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Victor Goswami",
              "values": [
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kory Ishisaka",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Mildred \"Millie\"  Reyes",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "ACC - Senior Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Linda Revilla",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Soojin Yoo",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Susan Sarinas",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Scott Okamoto",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "ACC Greenhaven Terrace",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Yesenia Jones",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "ACC Maple Tree",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Samia Kumar",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "ACE Senior Servicers",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Monalisa Silapan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "AD Fiduciary",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "John DiPiazza",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Ryan Anderson",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "AHC Home Health and Hospice ",
      "values": [
          1,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Roman Kravchenko",
              "values": [
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Edna Ochoa",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Preston Wright",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "a a",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "ALS Association",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Stephanie Cardenas",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "ANG Home Health",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Natalie Rachkov",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Olivia Ajeakwa",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Absolute Compassion Hospice",
      "values": [
          3,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kristi Hrbek",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Casey Simon",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "AccentCare",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jennifer Gamboa",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Tamara Stewart",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Francisca Zawaydeh",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Activities & Events - Sacramento",
      "values": [
          3,
          0,
          1,
          0,
          6,
          0,
          3,
          1,
          2,
          0,
          0,
          1
      ],
      "companyValues": [
          {
              "label": "Activity Summaries",
              "values": [
                  3,
                  0,
                  1,
                  0,
                  6,
                  0,
                  3,
                  1,
                  2,
                  0,
                  0,
                  1
              ]
          }
      ]
  },
  {
      "label": "Advance Health Care of Sacramento",
      "values": [
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Yolanda De La Rosa",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Deleted  Deleted",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Mario Tadeo",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Advance Home Health & Hospice",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jessica Armendariz",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Raquel Hernandez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Advanced Health Care SNF / Rehab - Sacramento",
      "values": [
          3,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kymerie Mayhew",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jon Walker",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Edna Ochoa",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Yolanda De La Rosa",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Yoianda De La Rosa",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Solia Lechuga",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Advanced Infusion Center",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Caprice Sanchez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Advanced Wellness",
      "values": [
          0,
          0,
          0,
          0,
          0,
          1,
          1,
          1,
          0,
          0,
          0,
          1
      ],
      "companyValues": [
          {
              "label": "Helen Justice",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  1,
                  1,
                  0,
                  0,
                  0,
                  1
              ]
          }
      ]
  },
  {
      "label": "AdventistHealth - Galt - Familly Practice",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dr. Jorge Oceguera MD",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Dr. Mohiuddin Waseem MD",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Dr. Sandra Roble-Bonham, PA-C",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "AdventistHealth - Sacramento - Orthopedic",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dr. Stephen Howel MD",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sonja Henderson",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Advocate Fiduciary Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Christa Kinsman",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Helen Bryant",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Joseph Arana",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Aegis of Carmichael",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kelly Smitley",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Michelle Connor",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Steffany Jamison",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kelly Stimbert",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Agency on Aging Area 4- Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Pam Miller",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "AgingAssistant",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "James Ozsvath",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Alars Home Health LLC",
      "values": [
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          1,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Anna Hedger",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Ray Robles",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sylvia Byrd",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": " ",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Alliance Insurance Co.",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Insurance Agent Unsure",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Almond Heights",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Breanna Grindel",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Eva Bowlin",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Karen Huckabee",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Myron Clifton",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Stephen MacDonald",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Terri Aguiar",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Lucinda Lecesne",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Alpha One",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Mike Ryder",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Tom Arjil",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Always Best Care",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Carrie Meyer",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Alzheimer's Aid Society",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Christina Ness",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sheryl Ashby",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Max Perry",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Preston Wright",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Roman Kravchenko",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Edna Ochoa",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kory Ishisaka",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Alzheimer's Association",
      "values": [
          4,
          0,
          1,
          0,
          5,
          0,
          0,
          1,
          0,
          0,
          0,
          1
      ],
      "companyValues": [
          {
              "label": "Alexandra Castillo-Weisgerber, ACSW",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Bonnie Chow",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Denise Davis",
              "values": [
                  3,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Dori Spraul",
              "values": [
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Amelia Darlin",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  5,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  1
              ]
          }
      ]
  },
  {
      "label": "Amari Home Health & Hospice",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Luba Kayreptan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Amber Peters",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Amber Peters",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "American River Care Center",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Grace Bagaybagayan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Salina Chhay",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Stephanie Vazquez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Amethyst Grove ( B&C's ) in Roseville",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Marc Allen",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Anderson Ehsani Fiduciary Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Mia Ehsani",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Annalisa Alvarado Fiduciary",
      "values": [
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Annalisa Alvarado",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Ansel Park",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Candy Woodby",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Applied",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Sarah Mangus",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Applied Healthcare",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Adrian Heredia",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Arden Post Acute Rehab",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Janee Farris",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jasmine Bridges",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Arias Home Health",
      "values": [
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "David Shirley",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Doris Neves",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Irina Riley",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Arise Senior Placement",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Allia Butac",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Asbury Park",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Akum Ugorji",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Debi Morales",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Doug Hawkins",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Letty Marker",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Maggie Belciug",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Assisted Living Locators",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jen Priest",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Associated Family Physicians",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jason Speaks FNP",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kyle Bond FNP",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sarah Alarabi MD",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Emily Njoroge FNP",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Atria - Carmichael",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Corazon \"Cora\" Buan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Reena Unknown",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Karie Williams",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Atria - El Camino Gardens",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Allison Perkes",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Atria - Rocklin",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dana Stancal",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Dyan Clark",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Barrier Reef Investments",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Michael DePhilippis",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Best Friends (Approach to Dementia Care)",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "David Troxel",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Bliss Care Home",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Sukhveen Bajaj",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Blossom Ridge Care Home",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Liz Strandin",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Blossom Ridge Home Health - Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Catherine Manriquez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Cindy Scaletta",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Gerald Agustin",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Rex Wyrick",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Liz Unsure",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Blossom Vale",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Natalie McGowan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Vanessa Romero",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Brightstar - Roseville",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kati Rozak",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Bristol Hospice - Roseville",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jill Vogt",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kelley Bailey",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Terri McKee",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Brookdale - Citrus Heights",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Albert Colmres",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Dale Masters",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kayla Young",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Brookdale - Folsom",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Briana Brown",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Shari Karnig",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Brookdale - Orangevale",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Danielle Morris",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Brookdale - Roseville",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Helen Day",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sherrie Kuar",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Brookfields Restaurant",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Joe Moore",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Bruceville Point",
      "values": [
          4,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Heather Michaud",
              "values": [
                  3,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Misty Veloz",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Bruceville Terrace",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kaycee King",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Lisa Davis, RN",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Shawna Unsure",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kim Khuu",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Burger Physicial Therapy - Elk Grove",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Casey And Katllin",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Eric Burger",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "CMY Fiduciary Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Carolyn Young",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kim Simms",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Lindsay Bowman",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Zachary Young",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "CSD Elk Grove",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Brita Geiger",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Cain Fiduciary Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kathryn Cain",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Cal Aim",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Cal Aim Services",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "California Dept. of Financial Protection and Innovatoni",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Katie Need to get",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "California Telephone Access Program",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Victoria Harling",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Camden Springs",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Chris and Laurie Barrow",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Campus Commons Senior Living",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Deanna Brewer",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Capitol Healthcare",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kayla Hilton",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Care First Resources",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jenny Fehlman",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Caring Network",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Cyndee Castle",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Caring.com",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Carlos Oliveras",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Carlton - Orangevale",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Alyssa Younan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Trudy Parker",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Carlton Plaza - Elk Grove",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Evangeline Rodriguez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Janell Ravera",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kendra Braida",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Misty Charles",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sheila Gulle",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Carlton Plaza - Orangevale",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Misty Charles",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Carlton Plaza - Sacramento",
      "values": [
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ally Watson",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Amanda Friedman",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Ana Cardenas",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Cal Mendiola",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Tyrisha Riser",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Yuvi Diaz",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kayleigh Daniels",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "CenterWell Home Health",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Tamara White",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Citrus Heights Terrace",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ashley Stahl",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "City of Galt - Commission on Aging",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Robert (Bob) Balliet",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "City of Roseville Public Works (transportation)",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Tricia Litts",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Clergy of Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Louella Garcia",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Colleen Watters Attorney at Law",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Colleen Watters",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "College Oak Nursing & Rehabilitation Center",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Rhean Pastor-Quismorio",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Comfort Keepers - Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Nadra Bingham",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Connect Hearing",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Rubina Sethi",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Country Club Manor",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "James Cowley",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "CountryHouse",
      "values": [
          5,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Karen  Wood",
              "values": [
                  5,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Craig Cares",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Carly Sims",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Creekside Church",
      "values": [
          3,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Bob Speiszer",
              "values": [
                  3,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Crosswood Oaks",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Angelica Huizar",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "CrystalView Optomertry",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dr. Vetrone Vetrone",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Daycation",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Paul Kelly",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Del Monte assisted living and memory care",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Sunny Saini",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Del Oro Caregiver Resource Center - Sacramento",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Amber Henning",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kassie Wesserman",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Matthew \"Matt\" Darytil",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Mendi Bates",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Simon Nazzal",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Stephani Wilson",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Stephanie Gunther",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Michelle Nevins",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Ashlie John",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Donna Deterding and Associates",
      "values": [
          2,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Donna Deterding",
              "values": [
                  2,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Downs Fiduciary Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Shannon Downs",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Dr. Nadal Khalili MD",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dr. Nidal Kahlili",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Dr. Stephen Howel MC",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Sonia Unsure",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "ELAK Health Concierge Medicine",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dr. Sarah Almilli, Pharm D, APh",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Eden Health Home Health",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Janice Deithch",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Edward Jones - David Brockway",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jennifer Neubauer",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Edward Jones - Roseville",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jon Benecke",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "El Hadi Mounderres M.D. Neurology",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Hadi Mouderres",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Elder Care Management - Sacramento",
      "values": [
          3,
          0,
          0,
          0,
          0,
          1,
          0,
          1,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Aimee Smith",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Felicia Juntunen",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Felicia Juntunen",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Gail Arno",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Nicolette Eberle",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Piper Jackson",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Elder Health Consulting LLC",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Emmah Russell",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Electronic Caregiver",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dot Boyd",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Elizabeth Dean Fiduciary Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Elizebeth \"Beth\" Dean",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Elk Grove Animal Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Koreena Walsh",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Elk Grove Chiropractic",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Stephan Von Seeger",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Elk Grove Park",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Oliver Aden",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Karan  Bassi",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Daiya Jorlen",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Elk Grove Police Department",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Gabriela Gonzalez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Elk Grove Senior Center",
      "values": [
          4,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Christine Cuddy",
              "values": [
                  4,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Elk Grove Vitamins",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dianna Singh",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Emmah T. Russel",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Emmah T. Russell",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Eskaton Gold River",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Sitarah McDonald",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Eskaton Land Park",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Latoya Pulanco",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Fair Oaks Healthcare Center",
      "values": [
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Reggie Coronica",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Salina  Chhay",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Karen Powel",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Family Matters",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jill Seoane",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "First Call Hospice",
      "values": [
          10,
          0,
          0,
          0,
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Christina Social Worker",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Cristine Brady",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "John Lee Duncan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Edna Ochoa",
              "values": [
                  10,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jenna Medina",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "First Light Home Care",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Paul Terpeluk",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Zee Tompkins",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Folsom Care Center",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Natalie Schmidt",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Four Seasons at Westshore",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jorge Alfaro",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Madeleine Faulkner",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Glenbrooke Community HOA",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kara Fong",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Gold Country Retirement Community",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Vicky Hume",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Golden Pond Senior Living",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Anna Fast",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Heather Murdock",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Mary Jane Gunto",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Miia Sakalata",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Good Shepherd Catholic Church",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Diane Marcotte",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Gramercy Court",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Greg Tanoy",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Mickii Maes",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Granite Bay Villas",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jessica Quistgard",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Shannon Hussman",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Grant Bennett Associates",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dawn Brenner",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Green Valley Hospice",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Habib Sherazee",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Mak Gholami",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Najmeen Sherazee",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Greenhaven Estates",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Linda Novak",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Tanya Jackson",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Lidsey Marottti",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Greenhaven Health Center",
      "values": [
          0,
          0,
          1,
          0,
          1,
          0,
          2,
          1,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kavita  Singh-Kumar",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Keith Johnson",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Aaron Ogara",
              "values": [
                  0,
                  0,
                  1,
                  0,
                  1,
                  0,
                  2,
                  1,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Linda Chhay",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Greenhaven Place",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Regina Sarinias",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "HICAP Services of Northern California",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Eva Schnittger",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Happy Memories Senior Care",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Solomon & Carmen Chis",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Harisharanam",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "react dev",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Healing Touch Home Health Care",
      "values": [
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Goar Mikayelyan",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "HealthFlex",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Gurpreet Unsure",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "HealthQuest DME",
      "values": [
          1,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Vanessa Grenyion",
              "values": [
                  1,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Healthy Living at Home",
      "values": [
          0,
          0,
          0,
          0,
          4,
          0,
          1,
          1,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Larry Shoemake",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Refrral Dev",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  4,
                  0,
                  1,
                  1,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Heart of Gold Medical Transport",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ron Unknown",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Heritage Lakeside",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Gina Unsure",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sandy Mclintock",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Heritage Oaks Hospital",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jamie DeJesus",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Home Instead",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jennifer Silagi",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Honor Thy Elders",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Caryl Abshire",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "House To Home Moving Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jacob Giorgi",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Housing Options Made Easy ( H O M E )",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Apiseta Tuioti",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "INFUSION for HEALTH",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ramy Hayek",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Indecare",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Cheryl Mays",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Marion McClelland",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kellianne Saephan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Independent Living Systems",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Marilyn Ojehonmon",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Innovage California Pace",
      "values": [
          2,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Erica Caldwell",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Marcie Rayl",
              "values": [
                  2,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Integra Personal Home Care Management",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jennifer Tremble",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Olivia Maldonado",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Iris Health Group",
      "values": [
          3,
          0,
          1,
          0,
          0,
          0,
          1,
          2,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Crystal Ruiz",
              "values": [
                  3,
                  0,
                  1,
                  0,
                  0,
                  0,
                  1,
                  2,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Wendy Carrion",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Iron Point",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Maria Tucay",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Ivy Park at Laguna Creek",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Michelle Swearingen",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Brittany Hall",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Karan Bassi",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Ivy Park at Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Beth Bohannan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Corrinne Shell",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Karla Weymouth",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Katherine Cancia",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Kaiser Health Education Department",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "John Camarillo",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Kaiser Home Health & Hospice - Roseville",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Misty Baptista",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Kaiser Hospice - Sacramento",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Cherie Sianez",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Kaiser Hospital - Roseville",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "JoAnne Moore",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Wendy Not given",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kaiser Roseville",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Kaiser Medical Center - South Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ana Not sure yet",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Morgan Yarber",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Kaiser Morse",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Leila Mohaddess",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Kaiser Palliative Care - Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Carol Hyden",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Kaiser Radiation/Oncology",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Carol Martin",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Kaiser Roseville Oncology",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Carol Unsure",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Kaiser Vacaville",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Janet Perrone",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Keller Williams RE",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jenny Sapien",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Kindred Home Health",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Tammy White",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "LPL Financial",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Luz Johnson",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Lakeside Pharmacy",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Stanley Leong",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Laurel Buys House",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Shasta Escalante",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Legacy Fiduciary Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Love Cia Miller",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Legacy Oaks of Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Zuniqua  Hicks",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Life Options For Seniors",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kristin Messer",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Shelly Coyne",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Lincoln Meadows Care Center",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "DeAnna Godfrey",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Lisa J. Berg & Associates",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Renee Kowalik-Moss",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Live Younger",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Christina Cowherd",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Looking For Care",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Debra Draves",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jennifer Valcazar",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kathy Inocelda",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "McILwain Mobility Solutions",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Chris McILwain",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Mckinley Park Care Center",
      "values": [
          1,
          0,
          1,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Amber Unsure",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Audrey Cotton",
              "values": [
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Audrey Cotton",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Meals on Wheels ACC",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "McKenna Klekman",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Michael Bustamonte",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Mercy General Acute Inpatient Rehabilitation",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Mary Nyguen ",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Mercy General Hospital",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ana Herrera",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Cassandra Cullivan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kathy Stegall",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Mercy Hospital - Folsom",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kelly Croteau",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Mercy Hospital - San Juan",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Suzette Walker",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Mercy McMahon Terrace",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Christy Wardlove",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kristina Wardlow",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Mary Erickson",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Mercy Medical Group - Elk Grove",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Choon-Weng (Andrew) Chan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Courtney Jee",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Mercy Medical Group - Midtown Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Aggie Roces",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Bettina Chrysofakis-Baidue",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Judith Unknown",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Mercy Medical Group Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Unsure Unsure",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Methodist Hospital - Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jeanette Wright",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Midtown Oaks Post Acute",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Mark Echon",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Michelle Unsure need to get",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Juliette Need to get",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Miracle.Ear",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Joel Merical",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Mission Carmichael Healthcare Center",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Monica Sanders",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Roxanne Henry",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Mistywood",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ed York",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Julie York",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "NAPT North Area Phycsical Therapy",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Alex Ray",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Ramona Lazar",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Neil Orchard Senior Activities Center",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Lara Nall",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Neurologist in Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dr. Nidal A. Khalili MD",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "New Leaf",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Diane Kidwell",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "New West Haven",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Joel Matkovich",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sabrina Long",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "New York Life - Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Anthony Chee",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "New York Life- Elk Grove",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Valerie Pruitt",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "NorCal Care Solutions ",
      "values": [
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Margo Staplin",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Northern California Caregiving Council",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Lou Basinal",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Northern California Orthopedic Associates",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Veronica Unsure",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "ONR (Orthopedic & Neurological Rehab",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dan Lara",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Oak Creek Hospice",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ledy Young",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Oakmont - East Sacramento",
      "values": [
          4,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Cat Burgess",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Janae Fernandez",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kelly Nanthasaeng",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Michelle Martinez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Laura Zamora",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Suzanne Giles",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kelli Hendrix",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Oakmont - Fair Oaks",
      "values": [
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Anyssa Hill",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Ashlee Bain",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Belinda Prunty",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Laurel Sanders",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Stephanie Young",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Tammy Smith",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kelly Nanthasaeng",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Oakmont - Folsom",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Julieanna Pina",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Nicole Flynn",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Oakmont - Roseville",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Stephanie Young",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Pari Manouchehri",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Fateema Brown",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Anamaria Cirmati",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Danielle Pejovich",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Oakwood Village",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Aren Verweij",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Josh Boneck",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Oasis Senior Advisors",
      "values": [
          1,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Laura Gilbeau",
              "values": [
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Samantha Gilbeau",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Olzack Health Care ",
      "values": [
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Marsha Santagato",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "One Simple Solution",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Julie Cosgrove",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Paradigm Benefit & Insurance Solutions",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dave Ghioroso",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Steve Jones",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Parkinson's Association",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Myron Jantzen",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sharon D'Nelly-Warady",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Pine Creek Care Center",
      "values": [
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ana Camara",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Ashley Haggard",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Neela Blon",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Peter Njuguna",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Ricky Maharaj",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Todd Pratt",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Platinum Senior Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kim Moes",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Premier Home Health Care & Hospice",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ed Alon, BSN RN",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Shaf Wahla",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Pro Health ",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Nurse Not given ",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "ProMedica ",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Johnny Rosales",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kathy Palacios",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Shelli Kramer",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Terri Ballesteros",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Qura Healthcare Inc",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Melisa Tiinder",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "RC Healthcare Hospice & Palliative Care",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Anaid Nersesyan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Olivia Johnson",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "RIL Resources for Independent Living",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Brandi Bluel",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Rancho Seco",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Diane Hernandez",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Reflections",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Susie Wentink",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Renee & Co.",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Renee Balcom",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Revere Court",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Nicole Hemenover",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Irene Charnell",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Paul Graham",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Corey Hubert",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jennifer Valcazar",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Right at Home - Folsom",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Mary Yeh",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Right at Home - Southplacer",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Nila Murallon",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Right at Home- Sacramento/Yolo",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Lyndsey Parker",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "RiverPointe Post-Acute, Carmichael",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Tamera Kilgore",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Rock Creek Care Center",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Rhoda Padpad",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Rose Senior Placement",
      "values": [
          3,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Cyndi Rose",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sandra Sandoval-Gonzales",
              "values": [
                  1,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Roseville Care Center",
      "values": [
          0,
          0,
          0,
          0,
          1,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Bitisho Kyubwa",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Hilda Ramirez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Maria Unknown",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Ranjit Singh",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jagdeep Kaur",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Roseville Care Center ",
      "values": [
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jagdeep Kaur",
              "values": [
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Roseville Commons",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Drew Marcus",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kathy Horbasch",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Loree Casto",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Roseville Health Care & Wellness Center",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Donna Lacavera",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Lisa Robinson",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Monica Talani",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Rosewood Post Acute Rehabilitation",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Mabel Tolang",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Tamara Kilgore",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Rx HealthCare Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jennifer Badillo",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sacramento Heart & Vascular Medical Associates",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Marisela Rivera",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sacramento Post Acute",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Krista Bellison",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sacramento Rehabilitation Hospital",
      "values": [
          8,
          0,
          0,
          0,
          5,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Amber White",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  3,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Cory Roberts CPTA, CI",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Priscilla Loringa",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Christina McCauley",
              "values": [
                  4,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Abriana \"Bri\" Robinson",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Carrie Bourque",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sailor Lane Health Care Center ",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Zanda Dunn",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Satellite Healthcare - Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Joy Cunanan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Saylor Lane",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Zanda Dunn",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Senior Care Solutions",
      "values": [
          5,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Carol Kinsel",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Christine Grmolyes",
              "values": [
                  3,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jessica Coyne",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Laura Clark",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Lauren Kinsel",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Caroline Easton",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Senior Care of Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Therese Johnson",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Senior Citizen Lawyer",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "R. Dustin MacFarlane",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Senior Placement Pro",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Brianna Maddy",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Senior Referral Network (SRN)",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Katherine Clay",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Senior Solutions",
      "values": [
          2,
          0,
          0,
          0,
          2,
          1,
          3,
          1,
          1,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Allyson Schloming",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  2,
                  1,
                  3,
                  1,
                  1,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sherwood Healthcare Center",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dustin Murray",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Mary Ortiz-Glover",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Tina Pak",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sierra Hematology & Oncology",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Laurie Victor",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Snowline Hospice",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Lynn Blankenheim",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sojourn Hospice and Palliative Care",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jody Unknown",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Rebecca Holland",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Somerford Place Memory Care - Roseville",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jane Scaparro",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sonder Home Health and Hospice Care",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Rozale Byrd",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sonrisa - Roseville - AL/MC",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Rachael Amador",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sonrisa - Roseville - IL",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Michelle Culbertson",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Storz Fiduciary Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Michael Storz",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Summerset Senior Living - Lincoln",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jo Franklin",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Tony Miccheal",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Summerset Senior Living - Rancho Cordova",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Emily Oliveras",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Rebekah Kagramayan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Victoria Olivarez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sun Oak Assisted Living",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Aida Ventosa",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "April Daly",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Douglas Wigner",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sun River Church",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jim Boyd",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Suncrest Hospice",
      "values": [
          3,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Clark Wright",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jess Methvin",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Rachel Van-Dyke",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Rocky Legaspi",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sunrise of Fair Oaks",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Joli Dfazio",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Lyndee Whaley",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sunrise of Rocklin",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Cyndi Dolan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "SuperCareHealth",
      "values": [
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Dacia Wilson",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sutter Care at Home - Roseville",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Aja \"Asia\" Manaffey",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Barbara Torres MBA",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jina Barton",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sutter Social Worker",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sutter Care at Home - Sacramento",
      "values": [
          3,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Moe Berger",
              "values": [
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Rebecca Fernandez",
              "values": [
                  3,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sumit Bhatka",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Discharge Planning Department",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sutter Medical - Elk Grove - Suite # 114",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Elizabeth Garamendi - Kann",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "George Hisatomi",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Nga Nguyen",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Scott Cannon",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sutter Medical - Elk Grove - Suite 101",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Chen (Emily) Chen",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Dr. Jared",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Dr. Todd Fisher",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Robert R Haynes",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sutter Medical Center - Sacramento",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Toni Contreraz-Tateishi",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sutter Physical and Hand Therapy",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Andreas Barth",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sutter Rehabilitation Institute",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Darcy Albrecht",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Monica Perez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Sutter Roseville Medical Center",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Abigail Tabulen",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "TAD Executive Fiduciary Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Therese Adams",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Terraces of Roseville",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Gladys Musamba-Desmariais",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Lillian Edick",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Tracy Da Oro",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Test Company",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jason Wu",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Commons at Elk Grove",
      "values": [
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Aimee Valencia",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Angelica Castaneda",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Laura Wellner",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Liza Spencer",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Meggin Cortez",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Commons of Elk Grove",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Meggin Cortez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Courte at Citrus Heights",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Andrea Armstrong",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kylee Whittaker",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Hannah Pryor",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jasmine Juchniewicz",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Gardens at Laguna Springs",
      "values": [
          5,
          0,
          0,
          0,
          3,
          0,
          1,
          0,
          0,
          0,
          0,
          1
      ],
      "companyValues": [
          {
              "label": "Barb Rose",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  3,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  1
              ]
          },
          {
              "label": "Monica Goings",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Misty  Williams",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Landing at Carmichael",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Natalie McNeal",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Legacy Law Group",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Tracy Potts",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Meadows Senior living",
      "values": [
          3,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Nekia Xavier",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Shauna Kraft",
              "values": [
                  3,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Shilah Ainsworth",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Nekia Xavier",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Shilah Ainsworth",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Meadows at Country Place",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Gilda Giner",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Oars Senior Lving",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Rae Ortiz",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jasneet Khangura",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Mai Vang",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Julie Henson",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Park at Laguna Springs",
      "values": [
          4,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Aileen \" Lynn\" Enrequez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Terrie Peters",
              "values": [
                  4,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Pines",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Carrin Jankowski",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Village at Heritage Park",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ashley Pedescleaux",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "desiree Tawney",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Faaleaga \"Anna\" Beckwith",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Kent Mulkey",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Shawn Oliver",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Tamara Zimba",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Tina Carza",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Villas at Stanford Ranch",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Teri Allen",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "The Woodlake",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Linda Maher",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Medina Van Alstyne",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Melissa Cannone",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Brian Sousa",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Timberlake Family Practice",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Joe Le, MD",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Truewood",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Julie York",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Turner Care Management",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Kara Turner",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "UC Davis Comprehensive Cancer Center",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Silvia Molina",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "UC Davis Medical Center",
      "values": [
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Christy Adams",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "David Buettner",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jennifer McFall",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jennifer Nordstrand",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "UCD Oncology",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Huroko Unknown",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Umpqua Bank",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Tina Pangelinan",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "United Methodist Church",
      "values": [
          4,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ruth Reynolds",
              "values": [
                  4,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "University Post Acute Rehab",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Derard Banton",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Jared Bake",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Arnel Batalla RN",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Misty Williams",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "VA Assist Veterans Home Care",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Carmen Perry",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Dwyla Young",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "VFW Department ",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Trish Need to find out",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Veterans Hall/American Legion",
      "values": [
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Karen Randles",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Vitas Hospice",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Stacey Bolinger",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Ty Butler",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Walmart Elk Grove Employee",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Keith Lexion",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "WellQuest",
      "values": [
          2,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Jessica Morales",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Monica Cardenas",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Tawni Gonzalez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Matthew Risher",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Heather McGruder",
              "values": [
                  2,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Wendy M. Moore Fiduciary Services",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Wendy M. Moore",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Western Slope",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Ashley Zimmer",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Whitney Oaks Care Center",
      "values": [
          0,
          0,
          0,
          0,
          1,
          0,
          1,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Fresha Karongo",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Gemma Cruz",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Angelia Guterrez",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  1,
                  0,
                  1,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Winding Commons",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Deborah Murphy",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Hilary Hardin",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Windsor El Camino",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Mylene Lee",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Wayne Daivs",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Windsor Elk Grove",
      "values": [
          4,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Josephil Perez, RN",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Maisha Masters",
              "values": [
                  1,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Margie Rios",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Wayne Davis",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Sandra Sandoval-Gonzales",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Woodside Healthcare Center",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Zanda Dunn",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Yolo Cares",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Monique Hollowell",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Your Elder Care Specialist",
      "values": [
          2,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Rebecca Johnson",
              "values": [
                  2,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "Your Home Assistant",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Cynthia Ceuellar",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          },
          {
              "label": "Mark Dwyer",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  },
  {
      "label": "exp Realty",
      "values": [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
      ],
      "companyValues": [
          {
              "label": "Melissa Lamberti",
              "values": [
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0,
                  0
              ]
          }
      ]
  }
];

  if ((type === 'applicants' || type === 'applicant-status' || type === 'prospects' || type === 'prospect-status' || type === 'care-manager-activities' || type === 'template' || type === 'userManagement' || type === 'awards-grouped' || tableName === 'awards-grouped' || type === 'referral-partners-detail')) {
    return (
      <>
        {rows.map((obj: any, i: any) => (
          <TableDropDown
            setCurrentRow={setCurrentRow}
            currentRow={currentRow}
            pageChangeHandler={pageChangeHandler}
            page={page}
            tableDropDownSort={tableDropDownSort}
            columns={columns}
            fetchData={fetchData}
            updateTable={updateTable}
            handleRemoveDropDown={handleRemoveDropDown}
            handleEditDropDown={handleEditDropDown}
            handleEdit={handleEdit}
            obj={obj}
            key={i} type={type || ''}
            handleArchive={handleArchive}
            hideArchive={hideArchive}
            handleRemove={handleRemove}
            tableName={tableName} />
        ))}
      </>
    );

  } else return (
    <>
      {displayRows.map((row: any, i: number) => (
        <>
          {/* <ExpendableRow
            key={i}
            row={row}
            isSubRowVisible={true}
            index={i}
            delta={[]}
            calculateTotal={() => null}
          ></ExpendableRow> */}
          <TableRow key={row.id} style={{ backgroundColor: i % 2 === 0 ? '#F8F9FA' : 'white', borderBottom: 'none' }}>

            {Object.keys(row).map((key) => {
              if (key === 'id' || key === 'state') {
                return ""
              }
              if ((key === 'description' || key === 'finalComments') && (tableName === 'marketingManagement' || tableName === 'Marketing-Activities')) {
                return (<TableCell key={uniqueId("tbl-cell-")} align="left" style={{ ...row[key]?.style, borderBottom: 'none' } || {}}>
                  <div className={'table-cell'}>{''}</div>
                </TableCell>)
              }
              return (
                <TableCell key={uniqueId("tbl-cell1-")} align="left" style={{ ...row[key]?.style, borderBottom: 'none' } || {}}>
                  <div className={tableName !== 'current-notes' ? "table-cell" : 'current-note-cell'}>{row[key]?.style ? row[key].value : typeof row[key] !== 'object' ? row[key] : null}</div>
                </TableCell>
              )
            })}

            <TableCell key={uniqueId("tbl-cell2-")} size="medium" align="center" style={type === 'current-notes' ? { float: 'right', borderBottom: 'none' } : { borderBottom: 'none' }}>
              <Stack direction="row" spacing={1} alignItems="flex-end" >
                {type === 'list' && (
                  <Link to={`/${row.careManager ? 'client' : 'care-partner'}/${row.id}`}>
                    <IconButton
                      sx={{
                        bgcolor: 'primary.main',
                        color: 'white',
                        marginX: '2px',
                      }}
                      aria-label="Client Details"
                    >
                      <AccountCircleIcon />
                    </IconButton>
                  </Link>
                )}
                {type !== 'singleApplicant' && type !== 'list' && type !== 'detail' && type !== 'noScheduleReport' && type !== 'batchAdd' && type !== 'applicant-status' && type !== 'referral-partners' && type !== 'referral-partners-detail' && type !== 'companies' && (
                  <IconButton
                    sx={{
                      bgcolor: 'primary.main',
                      color: 'white',
                    }}
                    aria-label="Edit Item"
                    onClick={(e) => handleEdit(row)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
                {type !== 'applicantDetails' && tableName !== 'Marketing-Activities' && tableName !== 'marketingManagement' && !hideArchive && type !== 'detail' && tableName !== 'current-notes' && type !== 'noScheduleReport' && type !== 'batchAdd' && tableName !== 'prospects' && (
                  <IconButton
                    sx={{
                      bgcolor: 'primary.main',
                      color: 'white',
                    }}
                    aria-label="Move To Archive"
                    onClick={(e) => handleArchive(row)}
                  >
                    {type === 'list' ? <RemoveCircleOutlineIcon /> : <ArchiveIcon />}
                  </IconButton>
                )}
                {hideArchive && tableName !== 'prospects-contacts' && tableName !== 'Marketing-Activities' && tableName !== 'marketingManagement' && tableName !== 'prospects' && type !== 'referral-partners' && type !== 'referral-partners-detail' && type !== 'companies' && tableName !== 'current-notes' && type !== 'singleApplicant' && type !== 'noScheduleReport' && type !== 'template' && type !== 'batchAdd' && type !== 'notes' && (
                  <IconButton
                    sx={{
                      bgcolor: 'primary.main',
                      color: 'white',
                    }}
                    onClick={(e) => {
                      handleArchive(row);
                    }}
                  >
                    <UnarchiveIcon />
                  </IconButton>
                )}

                {type === 'current-notes' && (
                  <IconButton
                    sx={{
                      bgcolor: 'primary.main',
                      color: 'white',
                      visibility: 'hidden'
                    }}
                    onClick={(e) => {
                      handleArchive(row);
                    }}
                  >
                    <UnarchiveIcon />
                  </IconButton>
                )}

                {type === 'singleApplicant' &&
                  // <NavLink to={`/recruiting/applicants/${row.state.id}`}>
                  <IconButton
                    sx={{
                      bgcolor: 'primary.main',
                      color: 'white',
                    }}
                    aria-label="Edit Item " onClick={() => { handleEdit(row) }}
                  >
                    <AccountCircleIcon />
                  </IconButton>
                  // </NavLink>
                }

                {type === 'referral-partners' &&
                  <NavLink to={`/marketing/referral-partners/${row?.state?.id}`}>
                    <IconButton
                      sx={{
                        bgcolor: 'primary.main',
                        color: 'white',
                      }}
                      aria-label="Edit Item "
                    >
                      <AccountCircleIcon />
                    </IconButton>
                  </NavLink>}

                {type === 'companies' &&
                  <NavLink to={`/marketing/company/${row?.state?.id}`}>
                    <IconButton
                      sx={{
                        bgcolor: 'primary.main',
                        color: 'white',
                      }}
                      aria-label="Edit Item "
                    >
                      <AccountCircleIcon />
                    </IconButton>
                  </NavLink>}
                {(tableName === 'Marketing-Activities' || tableName === 'marketingManagement' || type === 'notes' || tableName === 'prospects-contacts' || tableName === 'care-manager-activity-event') && (
                  <IconButton
                    sx={{
                      bgcolor: 'red',
                      color: 'white',
                      marginX: '2px',
                    }}
                    onClick={() => {
                      handleRemove && handleRemove(row)
                    }}
                    aria-label="Client Details"
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                )}

                {type !== 'list' && type !== 'detail' && type !== 'noScheduleReport' && type !== 'batchAdd' && type !== 'applicant-status' && row?.state?.hireDate && (
                  <IconButton
                    sx={{
                      background: 'green',
                      color: 'white',
                      cursor: "default"
                    }}
                    disableRipple
                    disableFocusRipple
                    className="hired"
                    aria-label="Hired"
                  >
                    <DoneIcon />
                  </IconButton>
                )}
              </Stack>
            </TableCell>
          </TableRow>
          {(tableName === 'prospects' && (type === 'applicants' || type === 'prospects')) && <TableRow style={{ backgroundColor: i % 2 === 0 ? '#F8F9FA' : 'white', borderTop: 'none' }}>
            <TableCell key={uniqueId("tbl-cell11-")}></TableCell>
            <TableCell key={uniqueId("tbl-cell12-")} colSpan={8}>
              {row?.comments?.value}
            </TableCell>
            <TableCell key={uniqueId("tbl-cell13-")} colSpan={2}></TableCell>
          </TableRow>}
          {((tableName === 'marketingManagement')) && <TableRow style={{ backgroundColor: i % 2 === 0 ? '#F8F9FA' : 'white', borderTop: 'none' }}>
            <TableCell key={uniqueId("tbl-cell14-")}></TableCell>
            <TableCell key={uniqueId("tbl-cell15-")} colSpan={8}  >
              {row?.description?.value}
            </TableCell>
            <TableCell key={uniqueId("tbl-cell16-")} colSpan={2}></TableCell>
          </TableRow>}


          {((tableName === 'Marketing-Activities')) && <TableRow style={{ backgroundColor: i % 2 === 0 ? '#F8F9FA' : 'white', borderTop: 'none' }}>
            <TableCell key={uniqueId("tbl-cell17-")}></TableCell>
            <TableCell key={uniqueId("tbl-cell18-")} colSpan={7} >
              {row?.finalComments?.value}
            </TableCell >
            <TableCell key={uniqueId("tbl-cell19-")} colSpan={2}></TableCell>
          </TableRow>}
        </>
      ))}
    </>
  );
}
export function TableContent({
  type,
  rowsPerPage,
  rows,
  handleEdit,
  page,
  handleArchive,
  hideArchive = false,
  tableName,
  handleRemove,
  handleEditDropDown,
  handleRemoveDropDown,
  fetchData,
  columns,
  tableDropDownSort,
  pageChangeHandler,
  currentRow,
  setCurrentRow,
  updateTable = () => { },
}: TableContentProps) {
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <TableBody>
      <TableContentDetails
        rows={rows}
        handleArchive={handleArchive}
        handleEdit={handleEdit}
        type={type}
        rowsPerPage={rowsPerPage}
        page={page}
        hideArchive={hideArchive}
        tableName={tableName}
        handleRemove={handleRemove}
        handleEditDropDown={handleEditDropDown}
        handleRemoveDropDown={handleRemoveDropDown}
        fetchData={fetchData}
        updateTable={updateTable}
        columns={columns}
        tableDropDownSort={tableDropDownSort}
        pageChangeHandler={pageChangeHandler}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
      />
    </TableBody>
  );
}