import { CardHeader, Layout } from 'components'
import { useState, useEffect } from 'react'
import { FilterHeader } from 'views'
import { useAuth } from 'hooks'
import ReferralsTable from 'views/Reporting/ReferralPartners/ReferralsTable'
import ReferralsByTypeTable from 'views/Reporting/ReferralPartners/ReferralsByTypeTable'
import ActivityReferralChart from 'views/Reporting/ReferralPartners/ActivityReferralChart'
// import ActivityReferralTypes from 'views/Reporting/ReferralPartners/ActivityReferralTypesChart'
import { formatQueryObj, generateData } from '../../views/Reporting/common';
import API from 'services/AxiosConfig'
import ActivityReferralTypesChart from 'views/Reporting/ReferralPartners/ActivityReferralTypesChart'
import ActivityReferralDeltasChart from 'views/Reporting/ReferralPartners/ActivityReferralDeltasChart'
import ActivityReferralTable from 'views/Reporting/ReferralPartners/ActivityReferralTable'
import ActivityReferralDeltaTable from 'views/Reporting/ReferralPartners/ActivityReferralDeltaTable'
import ActivityReferralRadarChart from 'views/Reporting/ReferralPartners/ActivityReferralRadarChart'

function ReferralPartnersReporting(props: any) {

    const { user } = useAuth();
    const [filter, setFilter] = useState({
        flag: false,
        excludeNoActivity: false,
        location: { id: user ? user?.location._id : '0', value: user ? user?.location?.location : 'All' },
        timeFrame: { id: 'last6Months', value: 'Last 6 Months' },
        referralPartner: { id: 'All', value: 'All' },
        company: { id: '', value: '' },
        companyType: { id: 'All', value: 'All' },

    })
    const [activityReferralData, setActivityReferralData] = useState([]);
    const [activityReferralDataType, setActivityReferralDataType] = useState([]);
    const [ReferralDeltas, setReferralDeltas] = useState([]);

    const [activityReferralTable, setActivityReferralTable] = useState([]);
    const [activityReferralTypeTable, setActivityReferralTypeTable] = useState([]);
    const [referralTypeTable, ReferralTypeTable] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    //const [referralTypeTable, SetReferralTypeTable] = useState([]);
    const [referralTypeDeltaTable,SetReferralTypeDeltaTable] = useState([]);

    const [radarCharData, setRadarCharData] = useState([]);

    useEffect(() => {
        const queryObj = formatQueryObj(filter)
        const postData = async () => {
            try {
                // Define the API endpoints and the payloads
                const api1 = 'referral-partners/monthly-beginning-prospects';
                const api2 = 'referral-partners/monthly-types';
                const api3 = 'referral-partners/fetch-deltas-type';
                const payload1 = queryObj;

                // Make multiple POST requests using Promise.all
                const requests = [
                    API.post(api1, payload1),
                    API.post(api2, payload1),
                    API.post(api3, payload1)
                ];

                const responses = await Promise.all(requests);

                // Extract the data from the responses
                const data: any = responses.map(response => response.data);
                // Set the responses
                setActivityReferralTable(data[0]?.data)
                setActivityReferralTypeTable(data[1]?.data)
                ReferralTypeTable(data[2]?.data)

                // For Chart
                let activityReferral: any = generateData(data[0])
                setActivityReferralData(activityReferral)
                let activityReferralType: any = generateData(data[1], "by_targetmarket_category")
                setActivityReferralDataType(activityReferralType)
                let referraldelta: any = generateData(data[2], "industry_category")
                setReferralDeltas(referraldelta)
                let delta: any = generateData(data[2], "delta")
                
                setRadarCharData(delta)

                setLoading(false);
            } catch (error: any) {
                setError(error.message);
                setLoading(false);
            }
        };

        postData();
    }, [filter]);
    return (
        <Layout>
            <div style={{ marginTop: '10%', marginBottom: '2%' }}>
                <FilterHeader type='referralReporting' setFilter={setFilter} filter={filter} />
            </div>
            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridGap: '30px',
                placeItems: 'center',
                paddingLeft: '130px',
                paddingRight: '100px'
            }}>
                <ActivityReferralChart loading={loading} activityReferralData={activityReferralData} />
                <ActivityReferralTypesChart loading={loading} activityReferralDataType={activityReferralDataType} />
                <ActivityReferralDeltasChart loading={loading} ReferralDeltas={ReferralDeltas} />
                <ActivityReferralRadarChart loading={loading} ReferralDeltasFiler={radarCharData} />
            </div>

            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridGap: '20px',
                placeItems: 'center',
                paddingLeft: '130px',
                paddingRight: '100px'
            }}>
                <ReferralsTable filter={filter} />
                <ReferralsByTypeTable filter={filter} />
                <ReferralsTable filter={filter} delta={true} referralTypeTable={referralTypeTable} loading={loading} />
                <ReferralsTable filter={filter} delta={true} url='referral-partners/fetch-deltas-type' />
            </div>
        </Layout>
    )
}

export default ReferralPartnersReporting
