import {  Spinner } from 'components'
import BarChart from 'components/Chart/BarChart'


function ActivityReferralTypesChart(props: any) {
    const {loading,activityReferralDataType } = props
    const activityChartData = {
        labels: activityReferralDataType?.actirefertypeheader,  // remove the first and last element, assuming they are not relevant for the chart
        datasets: [
           
            {
                label: '',
                data: activityReferralDataType?.activity,
                backgroundColor: '#7cb5ec',
            }
        ]
    };
    const ReferralChartData = {
        labels: activityReferralDataType?.actirefertypeheader,   // remove the first and last element, assuming they are not relevant for the chart
        datasets: [
           
            {
                label: '',
                data: activityReferralDataType?.refferal,
                backgroundColor: '#7cb5ec',
            }
        ]
    };
    const chartOptions = {
        maintainAspectRatio: false,
        layout: {
            padding: 25
        },
        scales: {

            x: {
                grid: {
                    display: false
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    maxTicksLimit: 10
                },
               

            }
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            datalabels: {
                color: 'white',
                display: function (context: any) {
                    return context.dataset.data[context.dataIndex] > 1; // or >= 1 or ...
                }
            }

        },
    }

    // referralData

    return (
<>
        <div style={{  height: '550px', width: '700px',  }}>
        <h3 style={{textAlign:"center"}} >Activity Totals by Target Market</h3>
            {activityChartData && !loading ? <BarChart chartData={activityChartData} options={chartOptions} renderLabels /> : <div><Spinner/></div>}

        </div>

        <div style={{ height: '550px', width: '700px',  }}>
        <h3 style={{textAlign:"center"}} >Referral Totals by Category</h3>
        {ReferralChartData && !loading ? <BarChart chartData={ReferralChartData} options={chartOptions} renderLabels /> : <div><Spinner/></div>}

        </div>
        </> 
    )
}

export default ActivityReferralTypesChart


