// @ts-nocheck
import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import {
  SelectChangeEvent,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Button,
  Autocomplete,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@emotion/react';
import { Select } from 'components';
import debounce from 'lodash/debounce';
import './InputGroup.css';
import { findCompanyKey } from 'lib';
import { MultiSelect } from 'components/Form/MultiSelect';

const theme = createTheme({});

const useStyles = makeStyles({
  input: {
    height: 40,
  },
});

export const InputGroup = ({ label, setFilter, filter, inputs, styles, maxWidth = 'xl', type, resetFilter, filterPartners, resetPartners, changeMilestone }: any): JSX.Element => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState<Date | null>(filter?.startDate?.value || null);
  const [endDate, setEndDate] = useState<Date | null>(filter?.endDate?.value || null);
  const [inputValue, setInputValue] = useState<string>('');
  const [inputValue2, setInputValue2] = useState<string>('');
  const [tempFilter, setFormData] = useState({
    ...filter,
  });
  const [selectedStageValues, setSelectedStageValues] = useState<string[]>(['All']);
  const [selectedMilestoneValues, setSelectedMilestoneValues] = useState<string[]>(['All']);

  useEffect(() => {
    setFormData(filter);
  }, [filter]);

  // useEffect(() => {
  //   const allData = inputs?.find(a => a.name === "milestone") || { items: [] };
  //   const keys = allData.items.map(item => item.key);
  //   setSelectedMilestoneValues(keys);
  // }, [inputs]);

  const handleMultipleSelectChange = (event: React.ChangeEvent<{ value: unknown }>, a: any, b: string) => {
    let selectedValues = event.target.value as string[];
    if (b === "milestone") {
      const allData = inputs?.find(a => a.name === "milestone") || { items: [] };
      const keys = allData.items.map(item => item.key);
      const lastItem = selectedValues[selectedValues.length - 1];
      const remainingItem = keys.filter(val => !selectedValues.includes(val));
      const deselectedItem = selectedMilestoneValues.find(val => !selectedValues.includes(val));

      if (lastItem === "All" || (remainingItem.length === 1 && remainingItem[0] === "All" && deselectedItem !== "All")) {
        setSelectedMilestoneValues(keys);
      } else if (selectedValues.includes("All")) {
        setSelectedMilestoneValues(selectedValues.filter(val => val !== "All"));
      } else if (selectedValues.length === keys.length) {
        setSelectedMilestoneValues(keys);
      } else if (deselectedItem === "All") {
        setSelectedMilestoneValues([]);
      } else {
        setSelectedMilestoneValues(selectedValues);
      }
    }

    else {
      setSelectedStageValues(selectedValues);
    }
    const tempFormData: any = {
      ...tempFilter,
    };
    const updatedFormElement = {
      ...tempFormData[b],
    };
    const value1 = event.target.value as string[];
    updatedFormElement.id = value1;
    updatedFormElement.value = value1;
    tempFormData[b] = updatedFormElement;
    setFormData(tempFormData);

    // console.log("tempFilter");
    // console.log(tempFilter);
  };

  const handleSelectChange = (event: SelectChangeEvent, items: any[], inputIdentifier: string) => {
    const tempFormData: any = {
      ...tempFilter,
    };
    const updatedFormElement = {
      ...tempFormData[inputIdentifier],
    };

    if (inputIdentifier === 'status' && event.target.value === 'Closed' && !tempFormData?.activity) {
      tempFormData.dateRange = { id: '7', label: 'Last 7 Days' };
    }
    updatedFormElement.id = event.target.value as string;
    updatedFormElement.value = items.find((item) => item.key === updatedFormElement.id).label;
    tempFormData[inputIdentifier] = updatedFormElement;
    setFormData(tempFormData);
  };

  const autoCompleteRenderOptions = (renderOptionDetails) => {
    const { props, option, data, name } = renderOptionDetails;
    switch (name) {
      case 'referralPartner':
        return (<li {...props} key={option._id}>
          <div className='dropdown-option'>
            <strong>{option.label}</strong>
            {data && <span>Status: {data?.status ? 'Active' : 'Inactive'}</span>}
            {data?.companyName?.companyName && (
              <span>Company Name: {data.companyName.companyName}</span>
            )}
            {data?.location?.location && (
              <span>Location: {data.location.location}</span>
            )}

          </div>
        </li>)
      case 'company':
      case 'companyName':
        return (
          <li {...props} key={option._id}>
            <div className='dropdown-option'>
              <strong>{option.label}</strong>
              {data && <span>Status: {data?.status ? 'Active' : 'Inactive'}</span>}
              {data?.location?.location && (
                <span>Location: {data.location.location}</span>
              )}

            </div>
          </li>);
      default:
        return (<li {...props} key={option._id}>
          {option.label}
        </li>)
    }
  };


  useEffect(() => {
    if (!tempFilter.startDate || !tempFilter.endDate) {
      return;
    }
    const tempFormData: any = {
      ...tempFilter,
    };
    const updatedFormElement = {
      ...tempFormData.startDate,
    };
    const updatedFormElement2 = {
      ...tempFormData.endDate,
    };
    updatedFormElement.value = startDate;
    updatedFormElement2.value = endDate;
    tempFormData.startDate = updatedFormElement;
    tempFormData.endDate = updatedFormElement2;
    setFormData(tempFormData);

  }, [startDate, endDate]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, inputIdentifier: string) => {
    const tempFormData: any = {
      ...tempFilter,
    };
    const updatedFormElement = {
      ...tempFormData[inputIdentifier],
    };
    const { value } = event.target;
    updatedFormElement.id = value;
    updatedFormElement.value = value;
    tempFormData[inputIdentifier] = updatedFormElement;
    setFormData(tempFormData);
    if (inputIdentifier === 'companyName') {
      setInputValue2(value)
    } else {
      setInputValue(value);
    }

  };


  const renderInputs = (input: any, index: number, style?: any) => {
    if (!input) return <></>;
    const { type } = input;
    if (type === 'button') {
      return (
        <Box sx={{ mb: 0.3, display: 'flex', alignItems: 'flex-end' }} key={index}>
          <Button
            variant="contained"
            onClick={(e) => {
              setFilter({
                ...filter,
                ...tempFilter,
              });
            }}
          >
            {input.label}
          </Button>
        </Box>
      );
    }
    if (type === 'select') {
      return (
        <Select
          key={index}
          value={tempFilter[input.name]?.id}
          onChange={(e: any) => {
            handleSelectChange(e, input.items, input.name);
          }}
          styles={input.styles}
          name={input.name}
          items={input.items}
          label={input.label}
        />
      );
    }
    if (type === 'autoComplete') {
      return (
        <Box key={index}>
          <FormControl sx={{ ...input.styles }}>
            <div
              className="bt"
            >
              {input.label}
            </div>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={[{ label: '', item: '' }, ...input.options.map((option: any) => ({ label: option.label, item: option.id, data: option?.data, name: input?.name }))]}
              renderOption={(props: any, option: any, { selected }) => autoCompleteRenderOptions({ props, option, data: option?.data, name: option?.name })}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              size="small"
              onChange={debounce((event: any, newValue: any, reason) => {
                const tempFormData: any = {
                  ...tempFilter,
                };
                const updatedFormElement: any = {
                  ...tempFormData[input.name],
                };
                const companyKey = findCompanyKey(tempFormData)
                if (newValue == null) {
                  updatedFormElement.id = '';
                  updatedFormElement.value = '';
                  tempFormData[input.name] = updatedFormElement;
                  if (input.name === companyKey) {
                    resetPartners && resetPartners();
                    tempFormData['referralPartner'] = { id: '', value: '' };
                  }
                  setFormData(tempFormData);
                  return
                }
                updatedFormElement.id = newValue.item || '';
                updatedFormElement.value = newValue.label || '';

                if (input.name === 'referralPartner') {
                  const updatedFormElementCompany: any = {
                    ...tempFormData[companyKey],
                  };
                  updatedFormElementCompany.id = newValue?.data?.companyName?._id || '';
                  updatedFormElementCompany.value = newValue?.data?.companyName?.companyName || '';
                  tempFormData[companyKey] = updatedFormElementCompany;
                  filterPartners && filterPartners(updatedFormElementCompany.id)
                }
                if (input.name === companyKey) {
                  filterPartners(updatedFormElement.id);
                  tempFormData['referralPartner'] = { id: '', value: '' };
                }
                tempFormData[input.name] = updatedFormElement;
                setFormData(tempFormData);
              }, 300)}
              value={tempFilter[input.name]?.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  id="my-input"
                  aria-describedby="my-helper-text"
                  name={input.name}
                  value={tempFilter[input.name]?.id}
                />
              )}
            />
          </FormControl>
        </Box>
      );
    }
    if (type === 'multiSelect') {
      return (
        <MultiSelect
          key={index}
          value={input.name === "stage" ? selectedStageValues : selectedMilestoneValues}
          //value={[tempFilter[input.name]?.id]}
          onChange={(e: any) => {


            handleMultipleSelectChange(e, input.items, input.name);
            //handleMultipleSelectChange(e);
          }}
          styles={input.styles}
          name={input.name}
          items={input.items}
          label={input.label}
        />
      );
    }
    if (type === 'checkbox') {
      return (
        <Box sx={{}} key={index}>
          <FormControlLabel
            checked={tempFilter[input.name]}
            value={tempFilter[input.name]}
            control={<Checkbox />}
            label={input.label}
            name={input.name}
            labelPlacement="top"
            onChange={(e) => {
              const target = e.target as HTMLInputElement;
              setFormData({
                ...tempFilter,
                [input.name]: target.checked,
              });
            }}
          />
        </Box>
      );
    }
    if (type === 'customMonthField' && tempFilter?.numberOfDays?.value === 'Custom') {
      return (<>
        <FormControl sx={{ ...input.styles }}>
          <Box>{'Number of Months'}</Box>
          <TextField
            id='numberOfMonths'
            type="text"
            aria-describedby="my-helper-text"
            value={inputValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputChange(e, 'numberOfMonths');
            }}
            style={{ height: '40px' }}
            InputProps={{ className: classes.input }}
          />
        </FormControl>
      </>)
    }
    if (type === 'customDateRange' && tempFilter.dateRange.value === 'Custom') {
      return (
        <Box key={index} sx={{ display: 'flex', width: '400px', gap: '12px', alignItems: 'flex-end' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ width: '50%' }}>
              <ThemeProvider theme={theme}>
                <DatePicker
                  label="Start Date"
                  value={tempFilter.startDate.value || startDate}
                  maxDate={endDate || null}
                  onChange={(newValue: any) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params: any) => <TextField size="small" {...params} />}
                />
              </ThemeProvider>
            </Box>
            <div className='custom-date-range-label'>
              <p>to</p>
            </div>
            <Box sx={{ width: '50%' }}>
              <DatePicker
                label="End Date"
                value={tempFilter.endDate.value || endDate}
                onChange={(newValue: any) => {
                  setEndDate(newValue);
                }}
                minDate={startDate || null}
                renderInput={(params: any) => <TextField size="small" {...params} />}
              />
            </Box>
          </LocalizationProvider>
        </Box>
      );
    }
    if (type === 'textField') {
      return (
        <>
          <FormControl sx={{ ...input.styles }}>
            <Box>{input.label}</Box>
            <TextField
              id={input.name}
              type="text"
              aria-describedby="my-helper-text"
              label=""
              value={tempFilter[input.name].value}
              // value={input?.name === 'companyName' ? inputValue2 : inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e, input.name);
              }}
              style={{ height: '40px' }}
              InputProps={{ className: classes.input }}
            />
          </FormControl>
        </>
      );
    }
  };
  let s = (label === '' || label === 'Prospect Grid' || label ==='Care Management') ? {} : { mt: 4, pt: 10 }
  return (
    <section>
      <Container maxWidth={maxWidth} sx={s}>
        <Box sx={{ my: label ? 4 : 8 }}>{label && <h2 className="pt">{label}</h2>}</Box>
        <Box sx={{ boxShadow: 4, p: 2, display: 'flex', flexGrow: 1, width: maxWidth === false ? '93%' : 'auto', marginLeft: !maxWidth ? '45px' : '0px' }}>
          <form style={{ ...styles }}>
            {inputs.map((input: any, i: number) => {
              return renderInputs(input, i);
            })}
          </form>
        </Box>
      </Container>
    </section>
  );
};