
// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
    TableContainer,
    Table as MuiTable,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Button,
    TablePagination
} from "@mui/material";
import * as XLSX from 'xlsx';
import { NoData, Spinner } from "components";
import zIndex from "@mui/material/styles/zIndex";
import RowComponent from "./ReferralPartners/Row";

interface RowData {
    label: string;
    values: Array<number | string>;
    companyValues?: any
}

interface Header {
    label: string;
    subHeaders?: string[];
}

interface TableProps {
    headers: Header[];
    rows: RowData[];
    sumRowLabel: string;
    sheetName?: string
    delta?: boolean,
    loading?: boolean
}

const ReferralReportingTable: React.FC<TableProps> = ({ headers, rows, sumRowLabel, sheetName, delta = TextTrackCueList, loading }) => {

    const [subRowVisible, setSubRowVisible] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [buttonDisabled,setButtonDisabled] = useState(false)

    useEffect(()=>{
        setPage(0);
    },[rows])

    // Handle change page
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    // Handle change rows per page
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when rows per page changes
    };

    const calculateSum = (data: RowData[]) => {
        let activityTotal = 0
        let referralTotal = 0
        let sumArr = new Array(data[0]?.values?.length).fill(0);
        data?.forEach(row =>
            row.values.forEach((value, index) => {
                if (typeof value === "number") {
                    sumArr[index] += value;
                    if (index % 2 === 0) {
                        activityTotal += value
                    } else {
                        referralTotal += value
                    }
                }

            })
        );
        let ratio:any = (referralTotal / activityTotal) || 0
        sumArr.push(activityTotal, referralTotal, ratio.toFixed(2))

        return sumArr;
    };

    const calculateTotal = (row: any, rowIndex: number, subRow: boolean) => {
        let activityTotal = 0;
        let referralTotal = 0;
        row?.values?.forEach((numVal: any, i: number) => {
            if (i % 2 === 0) {
                activityTotal += numVal
            } else {
                referralTotal += numVal
            }
        })
        let ratio = (referralTotal / activityTotal).toFixed(2)
        if (ratio == Infinity || ratio == 'NaN') {
            ratio = 'N/A'
        }

   
        return (
            <>
                <TableCell key={`activity-${rowIndex}`} align="center" style={{ borderRight: '1px dotted grey', borderBottom: !subRow && subRowVisible ? 'none' : '', height: '48px', width: '48px', position: 'sticky' }}>{activityTotal}</TableCell>
                <TableCell key={`referral-${rowIndex}`} align="center" style={{ borderRight: '1px solid grey', height: '48px', width: '48px', borderBottom: !subRow && subRowVisible ? 'none' : '', position: 'sticky' }}>{referralTotal}</TableCell>
                <TableCell key={`ratio-${rowIndex}`} align="center" style={{ height: '50px', width: '48px', borderBottom: !subRow && subRowVisible ? 'none' : '', position: 'sticky' }}>{ratio}</TableCell>
            </>
        )
    }

    const setColumnWidths = (ws: any, numOfCols: number, defaultWidth: number) => {
        const colWidths: any = Array.from({ length: numOfCols }, () => ({ wpx: defaultWidth }));
        colWidths[0] = { wpx: 175 }
        ws['!cols'] = colWidths;
    };

    const exportToExcel = () => {
        setLoading(true)
        const excelHeader = ['Referral Partner'];
        headers?.forEach(header => {
            if (header.subHeaders) {
                header.subHeaders.forEach(subHeader => {
                    excelHeader.push(header.label + ' ' + subHeader);
                });
            } else {
                excelHeader.push(header.label);
            }
        });
        const excelRows = rows?.map(row => {
            const rowObj = { 'Referral Partner': row.label };
            let valueIndex = 0;

            headers?.forEach(header => {
                if (header.subHeaders) {
                    header.subHeaders.forEach(() => {
                        const key = header.label + ' ' + header.subHeaders[valueIndex % header.subHeaders.length];
                        rowObj[key] = row.values[valueIndex++];
                    });
                } else {
                    rowObj[header.label] = row.values[valueIndex];
                    valueIndex++;
                }
            });

            return rowObj;
        });

        // Convert to worksheet
        const ws = XLSX.utils.json_to_sheet([excelHeader, ...excelRows], { skipHeader: true });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${sheetName}.xlsx` || "data.xlsx");
        setLoading(true)
    };


    const renderSumRow = (label: string, data: RowData[]) => {
        let sumValues = calculateSum(data);
        return (
            <TableRow style={{ borderTop: '2px solid black', fontWeight: 'bold' }}>
                <TableCell style={{ fontWeight: 'bold' }} align="center">{label}</TableCell>
                {sumValues.map((value, index) => (
                    <TableCell key={index} align="center" style={{ fontWeight: 'bold' }}>{value}</TableCell>
                ))}
            </TableRow>
        );
    };
    const expandAll = () => {
        setButtonDisabled(true)
        setSubRowVisible(prev => !prev)
    }

    const renderSubRow = (row: RowData, index: number) => {
        if (!subRowVisible) return null
        return (
            <TableRow key={`sub-${index}`} style={{ color: 'lightblue', fontSize: 'smaller' }}>
                <TableCell style={{ paddingLeft: '4ch', color: '#4768a5', fontSize: 'smaller' }}>
                    {row.label}
                </TableCell>
                {row.values.map((value, i) => (
                    <TableCell key={i} align="center" style={{ height: '35px', width: '48px', color: '#4768a5', borderRight: i % 2 === 0 ? '1px dotted grey' : '1px solid grey', borderLeft: i === 0 ? '1px solid grey' : '', }}>
                        {value}
                    </TableCell>
                ))}
                {!delta && calculateTotal(row, index, true)}
            </TableRow>
        );
    }

    const renderRow = (row: RowData, index: number) => (
        <>
            <TableRow key={index}>
                <TableCell align="left" style={{ borderBottom: row?.companyValues?.length && subRowVisible ? 'none' : '',  }}>{row.label}</TableCell>
                {row?.values?.map((value, i) => (
                    <TableCell key={i} align="center" style={{ borderRight: i % 2 === 0 ? '1px dotted grey' : '1px solid grey', borderLeft: i === 0 ? '1px solid grey' : '', height: '50px', width: '48px', borderBottom: !subRowVisible ? '' : 'none' }}>
                        {value}
                    </TableCell>
                ))}
                {!delta && calculateTotal(row, index, false)}
            </TableRow>
            {row?.companyValues?.map((subRow: any, subIndex: any) => renderSubRow(subRow, subIndex))}
        </>
    );

    // Inside TableBody

    <TableBody>
        {rows?.length === 0 ?
            <TableRow style={{ width: '100%' }}><TableCell colSpan={headers?.length}><NoData /></TableCell></TableRow> :
            rows?.map((row, i) => renderRow(row, i))}
        <TableRow sx={{ borderTop: "2px solid black" }} />
        {rows?.length > 0 ? renderSumRow(sumRowLabel, rows) : null}
    </TableBody>




    return (
        <div style={{ maxWidth: '1700px', width: '100%' }}>
            <Button variant="contained" color="primary" style={{ float: 'left', margin: 0, marginBottom: '5px' }} disabled={rows?.length <= 0 || buttonDisabled} onClick={() => setSubRowVisible(prev => !prev)} >
                {subRowVisible ? 'Collapse All' : 'Expand All'}
            </Button>
            {/* <Button variant="contained" color="primary" style={{ float: 'right', margin: 0, marginBottom: '5px' }} disabled={rows.length <= 0} onClick={exportToExcel} >
                Download Data
            </Button> */}
            <TableContainer component={Paper}  >
                <MuiTable size="small" >
                    <TableHead >
                        {!delta && <TableRow>
                            {headers?.map((header, i) => (
                                <TableCell
                                    style={{ backgroundColor: '#2f374e', color: 'white', borderRight: '1px solid white', borderLeft: '1px solid white', fontSize: 'smaller',width:'50px', minWidth: i === 0 ? '200px' : '75px' }}
                                    key={i}
                                    align="center"
                                    colSpan={header.subHeaders ? header.subHeaders.length : 1}
                                >
                                    {header.label}
                                </TableCell>
                            ))}
                        </TableRow>}
                        <TableRow style ={{}}>
                            {headers?.map((header, i) => (
                                <>
                                    {header.subHeaders ? (
                                        header.subHeaders.map((subHeader, j) => (
                                            <TableCell
                                                style={{ backgroundColor: '#2f374e', color: 'white', borderRight: '1px solid white', borderLeft: '1px solid white', fontSize: 'smaller', width:'20px',height:'20px' }}
                                                key={`${i}-${j}`}
                                                align="center"
                                            >
                                                {subHeader}
                                            </TableCell>
                                        ))
                                    ) : (
                                        <TableCell
                                            style={{ backgroundColor: '#2f374e', color: 'white' }}
                                            key={i}
                                            align="center"
                                        />
                                    )}
                                </>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {
                            loading ? (
                                // Render only the Spinner when loading
                                <TableRow>
                                    <TableCell
                                        colSpan={Math.min(headers?.length * 2, 18)}
                                        style={{ textAlign: 'center', padding: '20px' }}
                                    >
                                        <Spinner />
                                    </TableCell>
                                </TableRow>
                            ) : rows?.length === 0 ? (
                                // Render 'No Data' component or message if there are no rows and not loading
                                <TableRow style={{ width: '100%' }}>
                                    <TableCell
                                        colSpan={Math.min(headers?.length * 2, 18)}
                                        style={{ textAlign: 'center', padding: '20px' }}
                                    ><NoData /></TableCell>
                                </TableRow>
                            ) : (
                                // Render the table rows when not loading and rows are present
                                <>
                                    {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, i) => (
                                            <RowComponent
                                                key={i}
                                                row={row}
                                                isSubRowVisible={subRowVisible}
                                                index={i}
                                                delta={delta}
                                                calculateTotal={calculateTotal}
                                            />
                                        ))}
                                </>
                            )
                        }

                    </TableBody>

                </MuiTable>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]} // You can customize this array as needed
                component="div"
                count={rows.length} // Total number of rows
                rowsPerPage={rowsPerPage} // Rows per page
                page={page} // Current page
                onPageChange={handleChangePage} // Handle page change
                onRowsPerPageChange={handleChangeRowsPerPage} // Handle rows per page change
            />
        </div>
    );
};

export default ReferralReportingTable;