import { Modal as MuiModal, Box, FormControl, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { ComponentDivProps } from 'typings';
import API from '../../services/AxiosConfig';
import React, { CSSProperties, useEffect, useState, useCallback } from 'react';
import { useModal } from './ModalContext';
import { DatePickerInput } from 'views';

const modalBackdropStyle: CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const modalContentStyle: CSSProperties = {
  width: '25%',
  backgroundColor: 'background.paper',
  padding: 4,
  maxHeight: 'calc(100vh - 96px)',
  overflowY: 'auto',
};
// interface ModelWithCalender {
//   updateTable?: () => void;
// }


export function ModelWithCalender({ data, stateID, idxIndex, open, closeHandler, title, styles, toggleOpenDrop, updateTable, handleUpdateSubmit }: any) {
  const { openModal, closeModal } = useModal();
  const [values, setValues] = useState({});
  const [pdate, setPdate] = useState();

  useEffect(() => {
    if (open) {
      openModal(title);
    } else {
      closeModal(title);
    }
    setPdate(data?.fullDate ? data.fullDate : new Date().toISOString());
    handleChange(data?.label, (data?.fullDate ? data.fullDate : new Date().toISOString()), data.index, data.progressId)
    return () => closeModal(title);
  }, [open]);


  const handleChange = (name: any, value: any, i: number, progressId: string) => {
    let obj = { [name]: value !== null ? value.toString() : '', labelIdx: i, pid: progressId };
    setPdate(value)
    setValues(obj);
  };

  const handleUpdate = (id: any, vals: any, stateID: string, idxIndex: any) => {
    // setLoading(true)
    let url = `/applicants/progress/${id}`;
    if (data.prospectId) {
      url = `/prospects/progress/${id}`;
    }
    if (handleUpdateSubmit) {
      handleUpdateSubmit(url, id, vals, stateID, idxIndex, data);
    }
    else {
      API.put(url, { vals: { ...vals, stageId: stateID }, idx: idxIndex })
        .then((rsp: any) => {
          //closeHandler()
          toggleOpenDrop(true)
          updateTable()
        })
        .catch((error: any) => {
          console.error(error);
          toggleOpenDrop(true)
          updateTable()
        });
    }
  };


  return (
    <MuiModal
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div style={modalBackdropStyle}>
        <Box sx={styles ? { ...modalContentStyle, ...styles } : modalContentStyle}>
          {title && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 className="fs-30 text-primary">{title}</h2>
            </div>
          )}

          <DatePickerInput
            name={data.label}
            label={data?.label}
            initialValue={pdate}
            handleChange={handleChange}
            i={data.index}
          />

          <br></br>
          <br></br>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => closeHandler()} variant="contained" sx={{ bgcolor: '#a3a3ab' }} type="button">
              Cancel
            </Button>
            <Button
              onClick={(e: any) => handleUpdate(data.applicantId ? data.applicantId : data.prospectId, values, stateID, idxIndex)}
              variant="contained"
              sx={{ bgcolor: 'var(--primary-color)' }}
              type="button">
              Update
            </Button>
          </div>
        </Box>
      </div>
    </MuiModal>
  );
}
