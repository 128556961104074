import React, { useEffect, useRef, useState } from 'react';
import API from 'services/AxiosConfig';
import ReferralReportingTable from '../ReferralReportingTable';
import { formatQueryObj } from '../common';

const ReferralsTable = ({ filter, delta = false, url }: any) => {
    const [data, setData] = useState({ headers: [], rows: [] })
    const [loading, setLoading] = useState(true);

    const currentQueryRef = useRef(0);
    useEffect(() => {
        const queryObj = formatQueryObj(filter)
        setLoading(true)
        const queryNumber = ++currentQueryRef.current;

        if (delta === true) {
            API.post(url || 'referral-partners/fetch-deltas', queryObj)
                .then(({ data }: any) => {
                    if (currentQueryRef.current === queryNumber) {
                        setData(data.data)
                        setLoading(false);
                    }
                })
                .catch(err => {
                    if (currentQueryRef.current === queryNumber) {
                        setLoading(false);
                    }
                })
        } else {
            API.post('referral-partners/monthly-beginning-prospects', queryObj)
                .then(({ data }: any) => {
                    if (currentQueryRef.current === queryNumber) {
                        setData(data.data)
                        setLoading(false);
                    }
                })
                .catch(err => {
                    if (currentQueryRef.current === queryNumber) {
                        setLoading(false);
                    }
                })
        }
    }, [filter]);

    return <ReferralReportingTable loading={loading} delta={delta} headers={data?.headers || []} rows={data?.rows || []} sumRowLabel="Grand Totals" sheetName='Prospects by Activity & Referrals Count by Referral Partner' />

};

export default ReferralsTable;


